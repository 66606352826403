import { useId } from 'react';
import clsx from 'clsx';
import { usePopper } from 'react-popper';
import { format, isBefore, isValid, parse } from 'date-fns';
import { DateCalendar, InputLabel, InputWrapper } from '@pm/ui';
import { StyledInput, useToggle } from '@pm/core';
import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useClickOutside } from '../../hooks/use-click-outside/useClickOutside';

const MONTH_FORMAT = 'MM';
const DAY_FORMAT = 'dd';
const YEAR_FORMAT = 'yyyy';
const DEFAULT_DATE_FORMAT = `${MONTH_FORMAT}/${DAY_FORMAT}/${YEAR_FORMAT}`;

export interface InputDatePickerProps {
  disabled?: boolean;
  label?: string;
  name?: string;
  toDate?: Date;
  fromDate?: Date;
  placeholder?: string;
  selected?: Date;
  error?: string;
  'aria-labelledby'?: string;
  onInputClick?: () => void;
  onBlur?: (event: FocusEvent) => void;
  onChange?: (value: Date | null, event?: ChangeEvent) => void;
}

export const InputDatePicker = ({
  disabled = false,
  label,
  name,
  toDate,
  fromDate,
  placeholder,
  selected,
  error,
  'aria-labelledby': ariaLabelledBy,
  onChange,
  onBlur = () => {},
  onInputClick,
}: InputDatePickerProps) => {
  const disabledDates = toDate
    ? {
        after: toDate,
      }
    : [];

  const [dateInputValue, setDateInputValue] = useState<string>(
    selected ? format(selected, DEFAULT_DATE_FORMAT) : '',
  );
  const [uniqueInputId] = useId();
  const [isOpen, { toggle }] = useToggle();

  const popperWrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const popper = usePopper(popperWrapperRef.current, popperElement, {
    strategy: 'absolute',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start', 'bottom-start'],
        },
      },
      {
        name: 'offset',
        options: { offset: [0, 10] },
      },
    ],
  });

  const formatInputValue = useCallback(() => {
    setDateInputValue(selected ? format(selected, DEFAULT_DATE_FORMAT) : '');
  }, [selected]);

  useEffect(() => {
    // for when the date is selected in the day picker
    if (inputRef.current === document.activeElement) return;
    formatInputValue();
  }, [selected, formatInputValue]);

  const closePopper = useCallback(() => {
    toggle();
    inputRef?.current?.blur();
  }, [inputRef, toggle]);

  const openPopper = () => {
    if (!disabled) toggle();
  };

  const { removeListeners } = useClickOutside({
    elementRef: popperWrapperRef,
    onClickOutside: closePopper,
    onTabOutside: closePopper,
  });

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.currentTarget.value;
    setDateInputValue(inputValue);
    const yearLength = inputValue?.split('/')[2]?.length;

    const date = parse(inputValue, DEFAULT_DATE_FORMAT, new Date());
    const isAvailableDate =
      isValid(date) &&
      yearLength === YEAR_FORMAT.length &&
      (!toDate || isBefore(date, new Date(toDate)));

    if (onChange) onChange(isAvailableDate ? date : null, event);
  };

  const handleInputBlur = (event: FocusEvent) => {
    formatInputValue();
    if (onBlur) onBlur(event);
  };

  const handleDaySelect = (date?: Date) => {
    if (onChange) onChange(date || null);
    removeListeners();
    closePopper();
  };

  const handleEnter = () => {
    formatInputValue();
    removeListeners();
    closePopper();
  };

  return (
    <InputWrapper>
      {label && (
        <InputLabel htmlFor={`input_${uniqueInputId}`}>{label}</InputLabel>
      )}
      <div ref={popperWrapperRef}>
        <StyledInput
          id={`input_${uniqueInputId}`}
          name={name}
          aria-invalid={!!error}
          className={clsx('border-grey-500')}
          data-testid="calendar-date-picker-input"
          aria-labelledby={ariaLabelledBy}
          placeholder={placeholder}
          ref={inputRef}
          value={dateInputValue}
          maxLength={DEFAULT_DATE_FORMAT.length}
          onChange={handleInputChange}
          onClick={onInputClick}
          onFocus={() => {
            if (!isOpen) openPopper();
          }}
          onBlur={handleInputBlur}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleEnter();
            }
          }}
          disabled={disabled}
        />
        {isOpen && (
          <div
            data-testid="calendar-date-picker"
            ref={setPopperElement}
            className="z-1 bg-white border p-m rounded"
            role="dialog"
            tabIndex={-1}
            style={popper.styles.popper}
            aria-label="DayPicker calendar"
            {...popper.attributes.popper}
          >
            <DateCalendar
              disabled={disabledDates}
              defaultMonth={selected}
              endMonth={toDate}
              mode="single"
              selected={selected}
              startMonth={fromDate}
              todayHighlight
              onSelect={handleDaySelect}
            />
          </div>
        )}
      </div>
    </InputWrapper>
  );
};

export default InputDatePicker;
