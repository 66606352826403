import { Spinner } from '@pm/ui';

/**
 * Replaces entire content of modal with a formatted loading spinner.
 */
export function ModalLoadingSpinner() {
  return (
    <div className="flex flex-col my-6 mx-auto">
      <Spinner overlay />
    </div>
  );
}
