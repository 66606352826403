import { RefObject, useCallback, useEffect, useRef } from 'react';

interface UseClickOutsideProps {
  elementRef: RefObject<HTMLElement>;
  onClickOutside?: () => void;
  onTabOutside?: () => void;
}

export const useClickOutside = ({
  elementRef,
  onClickOutside,
  onTabOutside,
}: UseClickOutsideProps) => {
  const createdListenersRef = useRef(false);

  const handleActionOutside = useCallback(
    (event: MouseEvent | FocusEvent, action: () => void) => {
      if (
        elementRef.current &&
        !elementRef.current.contains(event.target as Node)
      ) {
        action();
        removeListeners();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementRef],
  );

  const handleOutsideClick: (event: MouseEvent) => void = useCallback(
    (event: MouseEvent) => {
      if (onClickOutside) handleActionOutside(event, onClickOutside);
    },
    [handleActionOutside, onClickOutside],
  );

  const handleOutsideTab: (event: FocusEvent) => void = useCallback(
    (event: FocusEvent) => {
      if (onTabOutside) handleActionOutside(event, onTabOutside);
    },
    [handleActionOutside, onTabOutside],
  );

  const createListeners = useCallback(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('focusin', handleOutsideTab);
    createdListenersRef.current = true;
  }, [handleOutsideClick, handleOutsideTab]);

  const removeListeners = useCallback(() => {
    document.removeEventListener('mousedown', handleOutsideClick);
    document.removeEventListener('focusin', handleOutsideTab);
    createdListenersRef.current = false;
  }, [handleOutsideClick, handleOutsideTab]);

  useEffect(() => {
    if (!elementRef.current) return;
    const targetElem = elementRef.current;

    // if element received focus, listen for clicks outside
    const controllerListener = (event: MouseEvent | FocusEvent) => {
      const hasActionListeners = !!createdListenersRef.current;
      const isFocusInsideTarget = elementRef.current?.contains(
        event.target as Node,
      );

      if (isFocusInsideTarget && !hasActionListeners) {
        createListeners();
      }
    };

    targetElem.addEventListener('focusin', controllerListener);

    return () => {
      targetElem.removeEventListener('focusin', controllerListener);
      removeListeners();
    };
  }, [elementRef, createListeners, removeListeners]);

  return { removeListeners };
};
