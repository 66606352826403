import {
  MedicationStatusEnum,
  useMarkTreatmentIneligibleMutation,
} from '@pm/graphql';
import { Dropdown, useToast } from '@pm/ui';
import { InfoIndicator } from '../InfoIndicator';
import { TextData } from '../TextData';
import { TextLabel } from '../TextLabel';
import { detectUserErrors } from '@pm/core';

type MedicationStatusProps = {
  label: string;
  status: MedicationStatusEnum | null;
  treatmentId?: string;
  canChange?: boolean;
};

export const MedicationStatus = ({
  label,
  status,
  treatmentId,
  canChange = false,
}: MedicationStatusProps) => {
  const [markTreatmentIneligible, { loading }] =
    useMarkTreatmentIneligibleMutation();
  const { positive, negative } = useToast();
  const { statusContent, tooltipContent } = getStatusLabel({ status });
  const showMedStatusDropdown =
    status === MedicationStatusEnum.NeedsClarification &&
    treatmentId &&
    canChange;

  return (
    <>
      <TextLabel>{label}</TextLabel>
      {showMedStatusDropdown ? (
        <Dropdown.Root
          name="medication-status"
          placeholder={statusContent}
          onChange={async () => {
            try {
              const { data } = await markTreatmentIneligible({
                variables: {
                  input: {
                    id: treatmentId,
                    eligibility: 'temporarily_ineligible',
                  },
                },
              });

              detectUserErrors(
                data,
                (res) => res.markTreatmentIneligible?.errors,
              );

              positive({
                content: 'Treatment marked as ineligible',
              });
            } catch {
              negative({
                content: 'Treatment could not be marked as ineligible',
              });
            }
          }}
        >
          <Dropdown.Item
            value="ineligible"
            text="Ineligible"
            disabled={loading}
          />
        </Dropdown.Root>
      ) : (
        <div className="flex items-center gap-1">
          <TextData ariaLabel={label}>{statusContent}</TextData>
          <InfoIndicator tooltipContent={tooltipContent} />
        </div>
      )}
    </>
  );
};

export function getStatusLabel({
  status,
}: {
  status: MedicationStatusEnum | null;
}) {
  switch (status) {
    case MedicationStatusEnum.Active:
      return {
        statusContent: 'Active',
        tooltipContent: 'Patient is taking medication',
      };
    case MedicationStatusEnum.Stopped:
      return {
        statusContent: 'Stopped',
        tooltipContent: 'Patient has stopped taking medication',
      };
    case MedicationStatusEnum.Paused:
      return {
        statusContent: 'Paused',
        tooltipContent: 'Patient has paused taking medication',
      };
    case MedicationStatusEnum.NeedsClarification:
      return {
        statusContent: 'Needs clarification',
        tooltipContent:
          'Patient needs to speak with a clinician before continuing',
      };
    case MedicationStatusEnum.Onboarding:
      return {
        statusContent: 'Onboarding',
        tooltipContent:
          'Patient is in the process of onboarding this treatment',
      };
    case MedicationStatusEnum.Ineligible:
      return {
        statusContent: 'Ineligible',
        tooltipContent: 'Patient is ineligible for this treatment',
      };
    default:
      return {
        statusContent: 'Pending',
        tooltipContent: 'Rx has not been prescribed',
      };
  }
}
