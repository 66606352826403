import {
  useEffect,
  useState,
  FunctionComponent,
  Dispatch,
  ReactNode,
} from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ApolloQueryResult } from '@apollo/client/index.js';

import {
  useCreateStripeSetupIntentMutation,
  Exact,
  GetPatientProfileQuery,
} from '@pm/graphql';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { ConfirmationDialog } from './ConfirmationDialog';
import { trackAnalyticsEvent } from '@pm/analytics';

export interface CreditCardFormFieldsProps {
  handleChange: (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent,
  ) => void;
  cardErrorMessage: string | null;
  cardNumberValid: boolean;
  cardExpiryValid: boolean;
  cardCvcValid: boolean;
  formSubmitted: boolean;
  isProvider: boolean;
  showDefaultCheckbox?: boolean;
}

export interface CreditCardFormProps {
  refetchProfile?: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetPatientProfileQuery>>;
  userId?: string; // required if used by a provider
  setDialogOpen?: Dispatch<boolean>;
  onSubmit?: (stripePaymentMethodId: string) => void;
  renderForm?: (props: CreditCardFormFieldsProps) => ReactNode;
  awaitConfirm?: () => Promise<boolean>;
  showDefaultCheckbox?: boolean;
  children?: ReactNode;
}

export const NewCreditCardFormWrapper: FunctionComponent<
  CreditCardFormProps
> = ({
  refetchProfile,
  userId,
  setDialogOpen,
  onSubmit,
  renderForm,
  awaitConfirm,
}) => {
  const { t } = useTranslation('payment-methods', {
    keyPrefix: 'Stripe',
  });
  const stripe = useStripe();
  const elements = useElements();
  const [create] = useCreateStripeSetupIntentMutation({
    variables: {
      input: { userId },
    },
  });
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardExpiryValid, setCardExpiryValid] = useState(false);
  const [cardCvcValid, setCardCvcValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [cardErrorMessage, setCardErrorMessage] = useState<null | string>(null);
  const handleChange = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent,
  ) => {
    if (event.elementType === 'cardNumber') {
      setCardNumberValid(event.complete);
    }
    if (event.elementType === 'cardExpiry') {
      setCardExpiryValid(event.complete);
    }
    if (event.elementType === 'cardCvc') {
      setCardCvcValid(event.complete);
    }
    if (event.error?.message) {
      setCardErrorMessage(t('FormErrors.CardErrorMessage'));
    }
  };
  const isProvider = !!userId;

  useEffect(() => {
    if (cardNumberValid && cardExpiryValid && cardCvcValid) {
      setCardErrorMessage(null);
    }
  }, [cardNumberValid, cardExpiryValid, cardCvcValid]);

  const schema = Yup.object({
    postalCode: Yup.string()
      .required('You must enter a postal code')
      .min(6, 'Your postal code must be at least 6 characters long'),
  });

  return (
    <Formik
      initialValues={{
        postalCode: '',
        isDefault: true,
      }}
      validationSchema={schema}
      validateOnMount={true}
      onSubmit={async (values, { setSubmitting }) => {
        if (!stripe || !elements) {
          toast.error('Stripe Elements failed to load');
          return;
        }

        const cardElement = elements.getElement('cardNumber');
        if (!cardElement) {
          toast.error('Stripe card element not found');
          return;
        }

        if (awaitConfirm) {
          const proceed = await awaitConfirm();
          if (!proceed) return;
        }

        const data = await create();
        if (!data?.data?.createStripeSetupIntent?.clientSecret) {
          toast.error('Creating stripe setup intent failed');
          return;
        }

        const result = await stripe.confirmCardSetup(
          data.data.createStripeSetupIntent.clientSecret,
          {
            payment_method: {
              card: cardElement,
              billing_details: {
                address: {
                  postal_code: values.postalCode,
                },
              },
            },
          },
        );

        if (result.setupIntent?.status === 'succeeded') {
          trackAnalyticsEvent('Payment Info Submitted');

          if (isProvider) {
            if (setDialogOpen) setDialogOpen(false);
            toast.success('Added patient credit card info', {
              duration: 4000,
            });
          } else {
            setConfirmationOpen(true);
          }

          if (refetchProfile) await refetchProfile();
          if (onSubmit) onSubmit(result.setupIntent.payment_method as string);
          setSubmitting(false);
          setFormSubmitted(true);
        } else {
          toast.error(result.error?.message || 'Error');
          setSubmitting(false);
        }
      }}
    >
      <Form className="w-full">
        {!isProvider && (
          <ConfirmationDialog
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
          />
        )}
        {renderForm &&
          renderForm({
            handleChange,
            cardErrorMessage,
            cardNumberValid,
            cardExpiryValid,
            cardCvcValid,
            isProvider,
            formSubmitted,
          })}
      </Form>
    </Formik>
  );
};
