import { detectUserErrors } from '@pm/core';
import { useUpdateMessageMutation } from '@pm/graphql';
import { useToast } from '@pm/ui';

export const useMessage = ({ messageId }: { messageId: string }) => {
  const { positive, negative } = useToast();
  const [updateMessageMutation] = useUpdateMessageMutation();

  const edit = async ({
    content,
    reason,
    onSuccess,
    onError,
  }: {
    content: string;
    reason: string;
    onSuccess: () => void;
    onError: () => void;
  }) => {
    try {
      const { data } = await updateMessageMutation({
        variables: {
          input: {
            id: messageId,
            reason: reason,
            params: {
              content,
            },
          },
        },
        refetchQueries: ['GetConversation'],
        awaitRefetchQueries: true,
      });

      detectUserErrors(data, (res) => res.updateMessage?.errors);

      positive({ content: 'Message updated' });
      onSuccess();
    } catch {
      negative({ content: 'Unable to update message' });
      onError();
    }
  };

  return { edit };
};
